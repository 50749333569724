<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-account-tie',
          title: 'Be a Client Member',
          text: 'The power to unleash Global and Local talent is at your fingertips. Let ScrumOnDemand provide experienced talent in your area of need and you will contribute to supporting communities of trained professionals from around the world.',
        },
        {
          icon: 'mdi-atom',
          title: 'Be a Talent',
          text: 'Be part of a global community of IT professionals contributing to a highly efficient world-wide workforce. Participate on a part-time or full-time basis and create your own schedule.',
        },
        {
          icon: 'mdi-human-capacity-increase',
          title: 'Join a Team',
          text: 'Tap into the power of being on a Scrum Team. Harness the strengths of each team member and  help each other become better together.',
        },
        {
          icon: 'mdi-bullseye-arrow',
          title: 'Post a Project',
          text: 'Client-posted projects are quickly matched against the skills and availability of the talent in our database. ScrumOnDemand facilitates the onboarding of staff to your project to get your project started in no time.',
        },
      ],
    }),
  }
</script>
